$(document).ready(function () {
    $(document).on('change', '.method', paymentChanged);

    function paymentChanged(evt) {
        evt.preventDefault();
        var method = $(evt.target).closest('.method').val();

        $.ajax({
            url: baseUrl + 'checkout/button/' + method, type: 'POST', dataType: 'json',
            success: function (rdata) {
                $('#payment-btn').html(rdata);
            }
        });
    }
});