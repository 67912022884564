
$(document).ready(function(){
  /*$('input').iCheck({
    checkboxClass: 'icheckbox_minimal-blue',
    radioClass: 'iradio_minimal-blue',
    increaseArea: '20%' // optional
  });*/


  $(".PRODUCT_LIST .page_title").click(function(){
    if($(".dropdown-cat").hasClass("closed")){
       $(".dropdown-cat").slideDown();
       $(".dropdown-cat").removeClass("closed")
    }
    else{
      $(".dropdown-cat").slideUp();
      $(".dropdown-cat").addClass("closed")
    }
  })


  $(".PRODUCT_LIST .size_cont,.PRODUCT_DETAIL .size_cont").click(function(){
    $(this).toggleClass("selected");
  })



  $("#count_plus").click(function(){
    var count = parseInt($("#prod_count").val());
    count++;
    $("#prod_count").val(count);
  })

   $("#count_minus").click(function(){
    var count = parseInt($("#prod_count").val());
    count--;
    if(count <= 0){
      count = 0;
    }
    $("#prod_count").val(count);
  })
});