$.ajaxSetup({
    data: {
        _token: csrfToken
    },
    beforeSend: function () {
        $(document).find('span.error-message-form').remove();
        $('.form-group').removeClass('has-error');
    },
    statusCode: {
        500: function () {
            showError("500 <i>Internal Server Error</i>");
        },
        401: function () {
            showError("401 <i>Unauthorized</i>");
        },
        403: function () {
            showError("403 <i>Forbidden</i>");
        },
        404: function () {
            showError("404 <i>Not Found</i>");
        },
        410: function () {
            showError("410 <i>This item was deleted or removed</i>");
        },
        422: function (data) {
            var errors = data.responseJSON;
            showError(processErrors(errors));
            $.each(errors, function (key, value) {
                $('#' + key).parent().addClass('has-error has-feedback');
                $.each(value, function (key2, value2) {
                    $('#' + key).parent().append("<span class='help-block error-message-form'>" + value2 + "</span>");
                });
            });
        }
    }
});

$(document).ajaxComplete(function () {
    $("[id=waitingContainer]").each(function () {
        $(this).fadeOut(500);
    });
});

$(document).ajaxStart(function () {
    showWaiting("Please wait.");
});